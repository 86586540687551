<template>
	<div class="page_content" style="padding-bottom:0">

        <div class="top_btn">
            <div class="left_btn" style="padding-top:5px;width:90%;">
				<div style="display:inline-block;">
					<div style="float:left;">已推送月份：</div>
                	<div class="month_text" :class="getTextColorBorder(item.status,1)" v-for="(item,index) in pushMonthData" :key="index" @click="handleSubmit(item)">
						<span class="month_activit" :class="nowMonth == item.month ? getTextColorBorder(item.status,2) : ''">{{ item.month }}</span>
					</div>
				</div>
                <div style="font-size:13px;margin-top:-10px;" v-html="pushMonthText"></div>
            </div>
            <div class="flex"></div>
            <div class="right_btn">
                <a-popconfirm title="您确定要导出该报表吗？" ok-text="确认" cancel-text="取消" @confirm="export_excel">
                    <a-button class="a_btn">导出</a-button>
                </a-popconfirm>
            </div>
        </div>

		<div class="total_main" v-if="totalNum > -1">
			<div>结案品项总数量：<span class="total_num">{{ totalNum }}</span></div>
			<div class="total_but" @click="showDetail = true">查看详细</div>
		</div>

        <a-table
			class="table_list"
            :scroll="{x: 2800}"
            :columns="columns"
            :pagination.sync="pagination"
            :dataSource="list"
            :loading="loading"
            @change="handleTableChange"
        ></a-table>

		<!-- 结案品项总数量详细 -->
		<RightDrawer title="经销商结案品项数量" :visible.sync="showDetail">
			<template slot="content">
				<a-table class="table_list" :columns="columnsDetail" :dataSource="totalCensus" :pagination="false"></a-table>
			</template>
		</RightDrawer>

	</div>
</template>

<script>
    import {getClosure,closureExport,getPushMonth,getClosureCensus} from "@/api/cost";
	import RightDrawer from "@/components/RightDrawer";

	const columns = [
		{
			title: "姓名",
			dataIndex: "username",
			scopedSlots: {
				customRender: "username"
			}
		},
		{
			title: "部门",
			dataIndex: "department",
            width:150,
			scopedSlots: {
				customRender: "department"
			}
		},
        {
			title: "客户ID",
			dataIndex: "customer_id",
			scopedSlots: {
				customRender: "customer_id"
			}
		},
        {
			title: "客户",
			dataIndex: "customer_name",
			scopedSlots: {
				customRender: "customer_name"
			}
		},
        {
			title: "城/乡",
			dataIndex: "region_type",
			scopedSlots: {
				customRender: "region_type"
			}
		},
        {
			title: "客户类型",
			dataIndex: "customer_type",
            width:150,
			scopedSlots: {
				customRender: "customer_type"
			}
		},
        {
			title: "门店地址",
			dataIndex: "address",
            width:220,
			scopedSlots: {
				customRender: "address"
			}
		},
        {
			title: "联系人",
			dataIndex: "contact_name",
			scopedSlots: {
				customRender: "contact_name"
			}
		},
        {
			title: "联系方式",
			dataIndex: "contact_phone",
            width:140,
			scopedSlots: {
				customRender: "contact_phone"
			}
		},
        {
			title: "投放类型",
			dataIndex: "launch_type",
			scopedSlots: {
				customRender: "launch_type"
			}
		},
        {
			title: "费用品相",
			dataIndex: "goods_title",
            width:250,
			scopedSlots: {
				customRender: "goods_title"
			}
		},
        {
			title: "签约数量",
			dataIndex: "goods_num",
			scopedSlots: {
				customRender: "goods_num"
			}
		},
		{
			title: "实际数量",
			dataIndex: "actual_num",
			scopedSlots: {
				customRender: "actual_num"
			}
		},
		{
			title: "金额",
			dataIndex: "cash",
			scopedSlots: {
				customRender: "cash"
			}
		},
		{
			title: "申请事由",
			dataIndex: "desc",
			scopedSlots: {
				customRender: "desc"
			}
		},
		{
			title: "经销商",
			dataIndex: "dealer",
			scopedSlots: {
				customRender: "dealer"
			}
		},
		{
			title: "签收时间",
			dataIndex: "customer_time",
			width:200,
			scopedSlots: {
				customRender: "customer_time"
			}
		},
	];

	const columnsDetail = [
		{
			title: "经销商",
			dataIndex: "dealer",
		},
		{
			title: "品项总数量",
			width:110,
			dataIndex: "value",
		}
	]

	export default {
		components: {
			RightDrawer
		},
		data() {
			return {
				columns,
				page: 1,
				pagination: {
					...this.$config.pagination
				},
				loading: false,
                list:[],
                query:{},
				pushMonthData:[],
                nowMonth:'',
				pushMonthText:'',

				columnsDetail,
				totalNum:0,
				totalCensus:[],
				showDetail:false,
			};
		},
		async created() {
            this.query = this.$route.query
            this.$nextTick(()=>{
                this.getPushMonth()
            })
		},
		methods: {
			export_excel(){
                closureExport({
                    data:{
                        id:this.query.id,
                        month:this.nowMonth,
                    }
                }).then(res=>{
					this.$message.success(res.info);
				})
            },
			getPushMonth(){
				getPushMonth({
					data:{
                        id:this.query.id
                    }
				}).then(res=>{
					if(res.data && res.data.length){
						this.pushMonthData = res.data
						this.nowMonth = this.pushMonthData[0].month
						this.getPushMonthText()
						this.getTableList()
						this.getClosureCensusInfo()
					}
				})
			},
			getPushMonthText(){
				let pushMonthText = ''
				this.pushMonthData.forEach(item=>{
					if(item.month == this.nowMonth){
						if(item.status == 4){
							pushMonthText = "<span style='color:green;'>所选推送月份："+item.month+"，当前状态为："+item.status_name+"</span>"
						}else if(item.status == 5){
							pushMonthText = "<span style='color:red;'>所选推送月份："+item.month+"，当前状态为："+item.status_name+"</span>"
						}else{
							pushMonthText = "<span style='color:orange;'>所选推送月份："+item.month+"，当前状态为："+item.status_name+"</span>"
						}
					}
				})
				this.pushMonthText = pushMonthText
			},
            handleSubmit(value) {
                if(this.nowMonth == value.month){
                    return;
                }
                this.nowMonth = value.month
                this.page = 1;
                this.pagination.current = 1;
                this.list = [];
				this.getPushMonthText()
                this.getTableList();
				this.getClosureCensusInfo()
            },
            handleTableChange(pagination) {
                this.pagination.pageSize = pagination.pageSize;
                this.page = pagination.current;
                this.getTableList();
            },
			getClosureCensusInfo(){
				getClosureCensus({
					data:{
						id:this.query.id,
                        month:this.nowMonth
					}
				}).then(res=>{
					this.totalNum = res.data.total
					this.totalCensus = res.data.census
				})
			},
            getTableList(){
                this.loading = true
                getClosure({
                    data:{
                        id:this.query.id,
                        month:this.nowMonth,
                        page:this.page
                    }
                }).then(res=>{
                    this.loading = false
                    this.list = res.data.list.data
                    let pagination = {...this.pagination}
					pagination.total = res.data.list.total;
					pagination.pageSize = res.data.list.per_page;
					pagination.current = res.data.list.current_page;
					this.pagination = pagination;
                })
            },
			getTextColorBorder(status,type){
				if(type == 1){
					return status == 4 ? 'month_text_green' : status == 5 ? 'month_text_red' : 'month_text_orange'
				}
				if(type == 2){
					return status == 4 ? 'month_activit_green' : status == 5 ? 'month_activit_red' : 'month_activit_orange'
				}
			},
		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");

    .month_text{
        margin-right:10px;
		margin-bottom:10px;
		float:left;
    }
	.month_activit{
		border:1px #ffffff solid;
		border-radius:5px;
		padding:2px 8px;
		cursor:pointer;
	}
	.month_text_red{
		color:red;
	}
	.month_text_green{
		color:green;
	}
	.month_text_orange{
		color:orange;
	}
    .month_activit_red{
        font-weight: bold;
		border:1px red solid;
    }
	.month_activit_green{
        font-weight: bold;
		border:1px green solid;
    }
	.month_activit_orange{
        font-weight: bold;
		border:1px orange solid;
    }

	.ant-table-tbody{
		tr{
			td{
				white-space: pre-wrap;
			}
		}
	}
	.ant-table-thead{
		tr{
			th{
				white-space: pre-wrap;
			}
		}
	}

	.total_main{
		margin-bottom:10px;
		display:flex;
	}
	.total_num{
		font-size:16px;
		font-weight: bold;
	}
	.total_but{
		color:#397dee;
		margin-left:10px;
		cursor:pointer;
		padding-top:2px;
	}
</style>
